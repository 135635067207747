const languageService = {
  english: {
    edit: "Edit",
    delete: "Delete",
    congratulations: "Congratulations",
    lastLeaderboardResults: "Last weekly leaderboard results",
    duration: "duration",
    confirm: "Confirm",
    workoutPrograms: "Workout programs",
    add: "Add",
    remove: "Remove",
    exercises: "Exercises",
    restTimeBetweenSets: "Rest time between sets",
    workoutProgramsHomeBtn: "WORKOUT PROGRAMS",
    createProgram: "Create program",
    buildProgram: "Build a program",
    updateProgram: "Update program",
    timer: "Timer",
    savedPrograms: "Saved Programs",
    addNewProgram: "Add program",
    sets: "sets",
    setsOf: "sets of",
    reps: "reps",
    workouts: "Workouts",
    cantHaveMoreWorkouts: (number) =>
      `Can't have more than ${number} workouts in one program`,
    editExercise: "Edit exercise",
    newExercise: "New exercise",
    name: "name",
    exercise: "exercise",
    login: "Login",
    createWorkoutHomeBtn: "CREATE WORKOUT",
    findWorkoutHomeBtn: "FIND A WORKOUT",
    pastWorkoutsHomeBtn: "PAST WORKOUTS",
    futureWorkoutsHomeBtn: "FUTURE WORKOUTS",
    friendsWorkoutsHomeBtn: "FRIENDS WORKOUTS",
    workoutInvitesHomeBtn: "WORKOUT INVITES",
    createWorkout: "Create workout",
    searchWorkouts: "Find a workout",
    pastWorkouts: "Past workouts",
    futureWorkouts: "Future workouts",
    friendsWorkouts: "Friends workouts",
    workoutInvites: "Workout invites",
    noDescriptionYet: "No description yet",
    weeklyStatisticsChart: "Last week exercise duration (minutes)",
    weekdays: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    workoutTypes: [
      "Everything",
      "Resistance Training",
      "Walking",
      "Running",
      "Biking",
    ],
    chats: "Chats",
    when: "When?",
    openForEveryone: "Open for both sexes",
    location: "Location",
    gettingCurrentLocation: "Getting your location...",
    setLocation: "Set location",
    clickToChangeLocation: "Click to change location",
    webMapsError: "Can't use maps on web, try from your smartphone :)",
    newWorkout: "New workout",
    details: "Details",
    optionalText: "Optional text",
    workoutTimePeiod: "Duration (hours:minutes)",
    language: "Language",
    chooseLanguage: "Choose language",
    showOnlineStatus: "Show online status",
    publicAccount: "Public account",
    settings: "Settings",
    privacy: "Privacy",
    changePassword: "Change password",
    logOut: "Log out",
    suggestionsAndBugs: "Suggestions & Bugs",
    reportABug: "Report a bug or just suggest new features!",
    noChangesWereMade: "No changes were made",
    applyChanges: "Apply changes",
    search: "Search",
    searching: "Searching...",
    searchUser: "Search user",
    editPersonalData: "Edit personal data",
    displayName: "Display name",
    description: "Description",
    changesSaved: "Changes applied",
    loading: "Loading",
    from: "From",
    to: "to",
    useLocationToMessureDistanceFromWorkout:
      "Use my location to messure distance from workout",
    showMenOnlyWorkouts: "Show me just men-only workouts",
    showWomenOnlyWorkouts: "Show me just female-only workouts",
    cantFindCityClickHere: "Can't find a certain city? Click here",
    cantFindCityExplenation:
      "If The city you're looking for doesn't appear here, it's because no workout has been created there yet. Be the first!",
    leagues: [
      "Iron",
      "Bronze",
      "Silver",
      "Gold",
      "Platinum",
      "Diamond",
      "Master",
      "GrandMaster",
      "Challenger",
      "Defending Champion",
    ],
    league: "League",
    message: ["Message", "Message"],
    friendRequest: "Friend Request",
    removeFriend: ["Remove Friend", "Remove Friend"],
    cancelRequest: ["Cancel Request", "Cancel Request"],
    getPointsToCompete:
      "You have zero points this week, get some points by participating in workouts in order to see this week's leaderboard!",
    forEveryone: "For everyone",
    menOnly: "Men only",
    womenOnly: "Women only",
    members: "Members",
    directions: "Directions",
    you: ["You", "You"],
    creator: ["Creator", "Creator"],
    inviteFriendsToJoin: "Invite friends to join",
    requests: "Requests",
    yourWorkout: "Your workout",
    updateAppToUseIt:
      "There is a new version of the app available for download. to make sure you don't run into bugs - download the latest version",
    update: "Update",
    addANewFriend: "Add a New Friend",
    friends: "Friends",
    hour: "Hour",
    day: "Day",
    today: "Today",
    yasterday: "Yasterday",
    tomorrow: "Tomorrow",
    scheduled: ["scheduled", "scheduled"],
    scheduledWorkout: [
      "Everything",
      "a Resistance Training",
      "a walk",
      "a run",
      "a bike ride",
    ],
    askToJoin: ["Ask to join!", "Ask to join!"],
    newMessage: "New message",
    leftTheWorkout: ["left the workout", "left the workout"],
    youAreTheNewAdmin: [
      "You are the new workout manager",
      "You are the new workout manager",
    ],
    theNewAdmin: "The new workout manager",
    acceptedYourFriendRequest: [
      "accepted your friend request",
      "accepted your friend request",
    ],
    wantsToBeYourFriend: [
      "sent you a friend request",
      "sent you a friend request",
    ],
    joinedYourWorkout: ["joined your workout", "joined your workout"],
    acceptedYourWorkoutRequest: [
      "accepted your request to join their workout",
      "accepted your request to join their workout",
    ],
    confirmYourWorkout: [
      "Confirm your scheduled workout to get points",
      "Confirm your scheduled workout to get points",
    ],
    wantsToJoinYourWorkout: [
      "wants to join your workout",
      "wants to join your workout",
    ],
    invitedYouToWorkout: [
      "invited you to her workout",
      "invited you to his workout",
    ],
    userIsDeleted: "This user got deleted",
    cannotSendMessagesToDeletedUser:
      "You cannot send messages to a deleted user",
    deleteUser: "Delete user",
    country: "Country",
    city: "City",
    workoutConfirmedDontLeave: [
      "Workout Confirmed! Don't leave the screen while we updating your place on leaderboard",
      "Workout Confirmed! Don't leave the screen while we updating your place on leaderboard",
    ],
    pointsAdded: "points added successfully. Enjoy your workout",
    exit: "Exit",
    acceptInvite: ["Accept invite", "Accept invite"],
    cancelWorkout: "Cancel workout",
    rejectInvite: ["Reject invite", "Reject invite"],
    leave: ["Leave", "Leave"],
    leaveWorkout: "Leave workout",
    cancelJoinRequest: "Cancel join request",
    requestToJoin: ["Request to join", "Request to join"],
    lessThan: "Less than ",
    kmAway: "kilometer away",
    kmsAway: "kilometers away",
    members: "Members",
    alreadyScheduledAWorkoutThisDate:
      "You have a scheduled workout in this date",
    chooseAnotherDate: ["Choose another date", "Choose another date"],
    yourWorkoutOverlappingOtherWorkout:
      "This workout overlaps another scheduled workout of yours",
    tryToScheduleAnEearlierWorkout:
      "Maybe you should try to schedule an earlier workout",
    gotIt: "Got it",
    cantGoBackInTime: "Can't go back in time",
    scheduleLater: [
      "Schedule a later starting time",
      "Schedule a later starting time",
    ],
    workoutMinutesPlaceholder: "Duration",
    youAre: ["You are", "You are"],
    meters: "meters",
    km: "kilometer",
    kms: "kilometers",
    fromTheWorkoutLocationGetCloser: [
      "from the workout location. Get close",
      "from the workout location. Get close",
    ],
    confirmWorkout: ["Confirm workout", "Confirm workout"],
    getInsideTheCircle: ["Get inside the circle", "Get inside the circle"],
    checkingDistance: "Checking distance",
    saveLocation: "Save location",
    landscapeModeMessage: "App is supported only in portrait mode",
    theUser: "The user",
    deletedSuccessfully: "deleted successfully",
    comingSoon: "Coming soon!",
    areYouSure: ["Are you sure?", "Are you sure?"],
    deleteChats: "Delete chats",
    cancel: "Cancel",
    accepted: ["Accepted", "Accepted"],
    rejected: ["Rejected", "Rejected"],
    accept: "Accept",
    reject: "Reject",
    invite: ["Invite", "Invite"],
    invited: ["Invited", "Invited"],
    joined: ["Joined", "Joined"],
    noFriendRequestsLeft: "No friend requests left",
    personalData: "Personal data",
    windowTooSmallMessage:
      "Window is too small, Please increase your browser window size",
    onlyWorkoutMembersCanSeeLocation:
      "Only workout members can see the location on the map",
    titleInstruction: "Title must be at least 5 characters",
    contentInstruction: "Content must be at least 15 characters",
    title: "Title",
    submit: "Submit",
    helpUsMakeAppBetter: "Help us make it better! ",
    submitting: "Submitting...",
    submittedSuccesfully: "Submitted Succesfully!",
    seconds: "seconds",
    minutes: "minutes",
    hours: "hours",
    days: "days",
    numberOfDays: (number) => (number == 1 ? "One day" : `${number} days`),
    betaVersion: "Beta version",
    untilTheWeekend: "until the weekend",
    youFinished: ["You finished", "You finished"],
    lastWeekend: "last weekend",
    place: "place",
    new: "new!",
    doYouWantToReportThisUser: [
      "Do you want to report this user?",
      "Do you want to report this user?",
    ],
    reportUserMessage: [
      "By reporting inappropriate behavior, you're helping us keep our app a safe and enjoyable place.",
      "By reporting inappropriate behavior, you're helping us keep our app a safe and enjoyable place.",
    ],
    continue: ["Continue", "Continue"],
    report: "Report",
    profileImageContainsNudity: "Profile image contains nudity",
    harassment: "Harassment",
    other: "Other",
    violationType: "Violation type",
    reportSubmittedTitle: "Report submitted successfully!",
    reportSubmittedMessage:
      "Thank you for reporting this user. Your contribution helps us create a positive environment for all",
    back: "Back",
    results: "Results",
    chooseLocation: ["Choose location", "Choose location"],
    workoutType: "Workout type",
    dateAndDuration: "Date and duration",
    preferences: "Preferences",
    inviteFriends: ["Invite friends", "Invite friends"],
    youCannotJoinThisWorkout: [
      "You cannot join this workout",
      "You cannot join this workout",
    ],
    thisWorkoutOverlapsPlannedWorkout:
      "This workout date and duration overlaps one of your planned workouts",
    showPlannedWorkout: "Show the planned workout",
    appRequiresInternetConnection:
      "The app requires internet connection to work",
    connectAndComeBack: "Connect and try again :)",
    clickForLocation: [
      "Click for personalized map",
      "Click for personalized map",
    ],
    locationPermissionOrInternetProblem:
      "It seems that we couldn't get your current location. You may not gave us permission/you were having some connection problems",
    tryAgain: ["Try again", "Try again"],
    whatAreYouLookingFor: [
      "What are you looking for?",
      "What are you looking for?",
    ],
    where: "Where?",
    choose: ["Choose", "Choose"],
    hoursMinutes: "hours:minutes",
    thisActionIsIrreversable: "This action is irreversable",
    appIsUnderMaintenance: "The app is currently under maintenance",
    comeBackLater: "Come back later :)",
    latestWorkouts: "Latest confirmed workouts",
    explore: "Explore",
    intervalTimer: "INTERVAL TIMER",
    refresh: "Refresh",
    controlPanel: "Control Panel",
    resetAppData: "Reset all app data",
    workout: "Workout",
    suggestedFriends: "Suggested friends for you",
    latestConfirmedWorkoutsWorldwide: "Latest confirmed workouts worldwide",
    mutualFriends: "Mutual friends",
    added: "Added",
    changeFilters: "Change filters",
    noWorkoutsMatchedYourSearch: "No workouts matched your search",
    haventConfirmedWorkoutsYet: "You haven't confirmed any workouts yet.",
    publicProgram: "Public program",
    youDontHaveScheduledWorkouts:
      "You don't have scheduled workouts at the moment",
    workoutNotFound: "The workout doesn't exists",
    itSeemsThatItGotCancledByTheCreator:
      "It was probably cancled by the creator",
    goToHomePage: "Go to Home Page",
    howDoYouWantToCallYourWorkoutProgram: [
      "How do you want to call your workout program?",
      "How do you want to call your workout program?",
    ],
    rememberThisCantBeChangedLater: [
      "Remember, this can't be changed later",
      "Remember, this can't be changed later",
    ],
    atLeast5Chars: "at least 5 characters",
    whatIsIt: "What is it?",
    publicProgramInformationTitle:
      "A public program can be seen and saved by other users. You are the only one that can edit the program.",
    publicProgramInformationContent:
      "If you set a program as public you won't be able to make it private later.",
    newLeaderboardMessage: ({ lastPoints, lastLeague, lastPlace }) => {
      var leagues = [
        "Iron",
        "Bronze",
        "Silver",
        "Gold",
        "Platinum",
        "Diamond",
        "Master",
        "GrandMaster",
        "Challenger",
        "Defending Champion",
      ];
      if (lastPoints == 0) {
        return `You haven't scored any points last week so you ${
          lastLeague == 0 ? "stayed at" : "moved down to"
        } ${leagues[lastLeague == 0 ? 0 : lastLeague - 1]} league`;
      } else if (lastPlace <= 10) {
        return `You finished at ${lastPlace} place last week so you ${
          lastLeague == leagues.length - 1 ? "stayed at" : "moved up to"
        } ${
          leagues[
            lastLeague == leagues.length - 1 ? lastLeague : lastLeague + 1
          ]
        } league`;
      } else if (lastPlace >= 11 && lastPlace <= 40) {
        return `You finished at ${lastPlace} place last week so you are staying in ${leagues[lastLeague]} league`;
      } else {
        return `You finished at ${lastPlace} place last week so you are ${
          lastLeague == 0 ? "staying in" : "moved down to"
        } ${leagues[lastLeague]} league`;
      }
    },
    pushNotificationModalTitle: [
      "Get notified when one of your friends are going for a workout!",
      "Get notified when one of your friends are going for a workout!",
    ],
    pushNotificationModalMessage:
      "We'll notify you when one of your friends scheduled a workout or remind you to exercise in the time you scheduled your own workouts",
    noPublicProgramsFound: "No public workout programs found.",
    createYourOwn: "Create your own",
    mostPopularPrograms: "Most popular programs",
    exploreNewPrograms: "Explore new programs",
    followers: "Followers",
    saveProgram: "Save program",
    stopFollow: ["Stop follow", "Stop follow"],
    editProgram: "Edit program",
    youHaventAddedFriendsYet: "You haven't added friends yet",
    areYouSureYouWantToDeleteMessages: (isMale) => {
      return "Are you sure you want to delete those messages?";
    },
    deleteForMe: "Delete for me",
  },
  hebrew: {
    edit: "עריכה",
    delete: "מחיקה",
    lastLeaderboardResults: "תוצאות טבלת הניקוד השבועית",
    congratulations: "כל הכבוד",
    workout: "אימון",
    login: "התחבר",
    createWorkoutHomeBtn: "יצירת אימון חדש",
    findWorkoutHomeBtn: "חיפוש אימונים",
    pastWorkoutsHomeBtn: "אימונים קודמים",
    futureWorkoutsHomeBtn: "אימונים מתוכננים",
    friendsWorkoutsHomeBtn: "אימונים של חברים",
    workoutInvitesHomeBtn: "הזמנות לאימונים",
    createWorkout: "יצירת אימון חדש",
    searchWorkouts: "חיפוש אימונים",
    pastWorkouts: "אימונים קודמים",
    futureWorkouts: "אימונים מתוכננים",
    friendsWorkouts: "אימונים של חברים",
    workoutInvites: "הזמנות לאימונים",
    noDescriptionYet: "אין תיאור עדיין",
    weeklyStatisticsChart: "זמן אימון בשבוע האחרון (דקות)",
    weekdays: ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שבת"],
    chats: "צ'אטים",
    createWorkout: "יצירת אימון",
    when: "מתי?",
    openForEveryone: "מעורב",
    location: "מיקום",
    clickForLocation: [
      "לחצי כאן לקבלת מפה מותאמת אישית",
      "לחץ כאן לקבלת מפה מותאמת אישית",
    ],
    gettingCurrentLocation: "טוען מיקום נוכחי...",
    setLocation: "בחירת מיקום",
    clickToChangeLocation: "שינוי מיקום",
    webMapsError:
      "אין אפשרות לראות את המפה בגרסה האינטרנטית, הורידו את האפליקציה לחווית משתמש טובה יותר :)",
    newWorkout: "אימון חדש",
    details: "פרטים",
    optionalText: "לא חובה",
    workoutTypes: ["הכל", "אימון כוח", "הליכה", "ריצה", "רכיבת אופניים"],
    workoutTimePeiod: "זמן אימון (דקות:שעות)",
    language: "שפה",
    chooseLanguage: "בחר/י שפה",
    showOnlineStatus: "הראה כשאני מחובר",
    publicAccount: "משתמש ציבורי",
    settings: "הגדרות",
    privacy: "פרטיות",
    changePassword: "שינוי סיסמה",
    logOut: "התנתקות",
    suggestionsAndBugs: "הצעות ובאגים",
    reportABug: "דווחו על באג או הצע פיצ'ר חדש שתרצו לראות פה! :)",
    noChangesWereMade: "לא נצפו שינויים",
    applyChanges: "שמירה",
    search: "חיפוש",
    searching: "מחפש...",
    searchUser: "חיפוש משתמש",
    editPersonalData: "עריכת מידע אישי",
    displayName: "כינוי",
    description: "תיאור",
    changesSaved: "השינויים עודכנו",
    loading: "טוען",
    from: "מ-",
    to: "עד-",
    useLocationToMessureDistanceFromWorkout:
      "הראה מרחק משוער מהאימון באמצעות מיקום נוכחי",
    showMenOnlyWorkouts: "חיפוש אימונים לגברים בלבד",
    showWomenOnlyWorkouts: "חיפוש אימונים לנשים בלבד",
    cantFindCityClickHere: "לא מוצא עיר מסויימת? לחץ כאן",
    cantFindCityExplenation:
      "אם עיר מסויימת אינה נמצאת ברשימה, זאת אומרת שעדיין לא היה אימון יחיד שנוצר שם, תהיו הראשונים!",
    league: "ליגה",
    leagues: [
      "ברזל",
      "ארד",
      "כסף",
      "זהב",
      "פלטינום",
      "יהלום",
      "מאסטר",
      "גרנד-מאסטר",
      "טוען לכתר",
      "אלוף",
    ],
    leagueTitles: [
      "ליגת ברזל",
      "ליגת ארד",
      "ליגת כסף",
      "ליגת זהב",
      "ליגת פלטינום",
      "ליגת יהלום",
      "ליגת מאסטר",
      "ליגת גרנד-מאסטר",
      "ליגת הטוענים לכתר",
      "ליגת האלופים",
    ],
    message: ["שלחי הודעה", "שלח הודעה"],
    friendRequest: "בקשת חברות",
    removeFriend: ["בטלי חברות", "בטל חברות"],
    cancelRequest: ["בטלי בקשה", "בטל בקשה"],
    getPointsToCompete:
      "0 נקודות השבוע, השג/י נקודות באמצעות השתתפות באימונים בשביל לראות את טבלת הניקוד השבועית.",
    forEveryone: "מעורב",
    menOnly: "לגברים בלבד",
    womenOnly: "לנשים בלבד",
    directions: "הוראות הגעה",
    you: ["את", "אתה"],
    creator: ["מנהלת", "מנהל"],
    inviteFriendsToJoin: "הזמנת חברים לאימון",
    requests: "בקשות הצטרפות",
    yourWorkout: "אימון שלך",
    updateAppToUseIt:
      "האפליקציה עברה עדכון מאז השימוש האחרון. למניעת באגים/קריסה יש לעדכן את האפליקציה",
    update: "עדכון",
    addANewFriend: "הוספת חבר חדש",
    friends: "חברים",
    hour: "שעה",
    day: "יום",
    today: "היום",
    yasterday: "אתמול",
    tomorrow: "מחר",
    scheduled: ["קבעה", "קבע"],
    scheduledWorkout: ["הכל", "אימון כוח", "הליכה", "ריצה", "רכיבת אופניים"],
    askToJoin: ["בקשי להצטרף!", "בקש להצטרף!"],
    newMessage: "הודעה חדשה",
    leftTheWorkout: ["עזבה את האימון", "עזב את האימון"],
    youAreTheNewAdmin: ["את המנהלת החדשה", "אתה המנהל החדש"],
    theNewAdmin: "המנהל החדש",
    acceptedYourFriendRequest: [
      "אישרה את בקשת החברות שלך",
      "אישר את בקשת החברות שלך",
    ],
    wantsToBeYourFriend: ["שלחה לך בקשת חברות", "שלח לך בקשת חברות"],
    joinedYourWorkout: ["הצטרפה לאימון שלך", "הצטרף לאימון שלך"],
    acceptedYourWorkoutRequest: [
      "אישרה את בקשת ההצטרפות שלך לאימון",
      "אישר את בקשת ההצטרפות שלך לאימון",
    ],
    confirmYourWorkout: [
      "אשרי את האימון שלך בשביל לקבל נקודות",
      "אשר את האימון שלך בשביל לקבל נקודות",
    ],
    wantsToJoinYourWorkout: [
      "רוצה להצטרף לאימון שלך",
      "רוצה להצטרף לאימון שלך",
    ],
    invitedYouToWorkout: ["הזמינה אותך לאימון", "הזמין אותך לאימון"],
    userIsDeleted: "המשתמש אינו קיים יותר",
    cannotSendMessagesToDeletedUser:
      "אין אפשרות לשלוח הודעות למשתמש שאינו קיים יותר",
    deleteUser: "מחיקת משתמש",
    country: "ארץ",
    city: "עיר",
    workoutConfirmedDontLeave: [
      "האימון אושר! אנא המתיני בזמן שאנו מעדכנים את המיקום שלך בטבלת הניקוד",
      "האימון אושר! אנא המתן בזמן שאנו מעדכנים את המיקום שלך בטבלת הניקוד",
    ],
    pointsAdded: "נקודות נוספו בהצלחה! אימון מוצלח",
    exit: "יציאה",
    acceptInvite: ["אשרי הזמנה", "אשר הזמנה"],
    cancelWorkout: "ביטול אימון",
    rejectInvite: ["סרבי להזמנה", "סרב להזמנה"],
    leave: ["עזבי", "עזוב"],
    leaveWorkout: "יציאה מהאימון",

    cancelJoinRequest: "ביטול בקשת הצטרפות",
    requestToJoin: ["בקשי להצטרף", "בקש להצטרף"],
    lessThan: "פחות מ",
    kmAway: "קילומטר ממך",
    kmsAway: "קילומטרים ממך",
    members: "משתתפים",
    alreadyScheduledAWorkoutThisDate: "יש לך אימון בשעה הזאת כבר",
    chooseAnotherDate: ["בחרי תאריך אחר", "בחר תאריך אחר"],
    yourWorkoutOverlappingOtherWorkout:
      "האימון הזה חופף את זמן האימון של אימון אחר שקבעת",
    tryToScheduleAnEearlierWorkout: "אולי כדאי לבחור שעה מוקדמת יותר",
    gotIt: "הבנתי",
    cantGoBackInTime: "אי אפשר לחזור אחורה בזמן",
    scheduleLater: ["בחר שעת התחלה מאוחרת יותר", "בחר שעת התחלה מאוחרת יותר"],
    workoutMinutesPlaceholder: "זמן אימון (דקות:שעות)",
    youAre: ["את", "אתה"],
    meters: "מטרים",
    kms: "קילומטרים",
    kms: "קילומטר",
    fromTheWorkoutLocationGetCloser: [
      "ממיקום האימון. תתקרבי",
      "ממיקום האימון. תתקרב",
    ],
    confirmWorkout: ["אשרי אימון", "אשר אימון"],
    getInsideTheCircle: ["כנסי לעיגול", "כנס לעיגול"],
    checkingDistance: "בודק מרחק",
    saveLocation: "בחירת מיקום",
    landscapeModeMessage: "האפליקציה נתמכת רק במסך מאונך",
    theUser: "המשתמש",
    deletedSuccessfully: "נמחק בהצלחה",
    comingSoon: "חכו לעדכון!",
    areYouSure: ["את בטוחה?", "אתה בטוח?"],
    deleteChats: "מחיקת צ'אטים",
    cancel: "ביטול",
    accepted: ["אושרה", "אושר"],
    rejected: ["סורב", "סורב"],
    accept: "אישור",
    reject: "סירוב",
    noFriendRequestsLeft: "אין עוד בקשות חברות",
    invite: ["הזמיני", "הזמן"],
    invited: ["מוזמנת", "מוזמן"],
    joined: ["הצטרפה", "הצטרף"],
    personalData: "מידע אישי",
    windowTooSmallMessage:
      "חלון הדפדפן שלך קטן מדיי, הגדל אותו לשימוש באפליקציה",
    onlyWorkoutMembersCanSeeLocation:
      "רק חברי האימון יכולים לראות את המיקום המדוייק במפה",
    titleInstruction: "הנושא חייב להיות באורך של 5 תווים לפחות",
    contentInstruction: "התוכן חייב להיות באורך של 15 תווים לפחות",
    title: "נושא",
    submit: "שליחה",
    submitting: "שולח...",
    submittedSuccesfully: "נשלח בהצלחה!",
    helpUsMakeAppBetter: "עזרו לנו לשפר את חווית השימוש שלכם!",
    seconds: "שניות",
    minutes: "דקות",
    hours: "שעות",
    days: "ימים",
    numberOfDays: (number) => (number == 1 ? "יום אחד" : `${number}` + " ימים"),
    betaVersion: "גרסת בטא",
    untilTheWeekend: "עד סוף השבוע",
    youFinished: ["את סיימת", "אתה סיימת"],
    lastWeekend: "בסוף השבוע האחרון",
    place: "דירוג",
    new: "חדש!",
    doYouWantToReportThisUser: [
      "את בטוחה שאת רוצה לדווח על המשתמש הזה?",
      "אתה בטוח שאתה רוצה לדווח על המשתמש הזה?",
    ],
    reportUserMessage: [
      "באמצעות דיווח על התנהגות לא הולמת, את עוזרת לנו לשמור על האפליקציה שלנו כמקום בטוח ונעים לשימוש.",
      "באמצעות דיווח על התנהגות לא הולמת, אתה עוזר לנו לשמור על האפליקציה שלנו כמקום בטוח ונעים לשימוש.",
    ],
    continue: ["המשיכי", "המשך"],
    report: "דיווח",
    profileImageContainsNudity: "תמונת הפרופיל מכילה עירום",
    harassment: "הטרדה",
    other: "אחר",
    violationType: "סוג הפרה",
    reportSubmittedTitle: "הדיווח נשלח בהצלחה",
    reportSubmittedMessage:
      "תודה שדיווחת על המשתמש. התרומה שלך עוזרת לנו ליצור סביבה חיובית לכל המשתמשים :)",
    back: "אחורה",
    results: "תוצאות",
    chooseLocation: ["בחרי מיקום", "בחר מיקום"],
    workoutType: "סוג אימון",
    dateAndDuration: "תאריך ואורך אימון",
    preferences: "העדפות",
    inviteFriends: ["הזמיני חברים", "הזמן חברים"],
    youCannotJoinThisWorkout: [
      "את לא יכולה להצטרף לאימון הזה",
      "אתה לא יכול להצטרף לאימון הזה",
    ],
    thisWorkoutOverlapsPlannedWorkout:
      "האימון הזה חופף לאחד מהאימונים המתוכננים שלך",
    showPlannedWorkout: "הראה את האימון",
    appRequiresInternetConnection:
      "אי אפשר להשתמש באפליקציה ללא חיבור לאינטרנט",
    connectAndComeBack: "התחברו וחזרו מאוחר יותר :)",
    locationPermissionOrInternetProblem:
      "לא הצלחנו לקבל את המיקום הנוכחי שלך. כנראה שיש לך בעיות תקשורת/לא קיבלנו ממך אישור להשתמש במיקום שלך",
    tryAgain: ["נסי שוב", "נסה שוב"],
    whatAreYouLookingFor: ["מה את מחפשת?", "מה אתה מחפש?"],
    where: "איפה?",
    choose: ["בחרי", "בחר"],
    hoursMinutes: "דקות:שעות",
    thisActionIsIrreversable: "הפעולה הזאת בלתי הפיכה",
    appIsUnderMaintenance: "האפליקציה בשיפוצים",
    comeBackLater: "תחזרו מאוחר יותר :)",
    latestWorkouts: "האימונים האחרונים שאושרו",
    explore: "סקירה",
    intervalTimer: "אינטרוואלים",
    refresh: "רענון",
    controlPanel: "פאנל ניהול",
    resetAppData: "איפוס האפליקציה",
    workoutProgramsHomeBtn: "תוכניות אימונים",
    createProgram: "יצירת תוכנית",
    buildProgram: "בניית תוכנית",
    timer: "טיימר",
    savedPrograms: "תוכניות אימונים שמורות",
    addNewProgram: "הוספת תוכנית",
    setsOf: "סטים של",
    reps: "חזרות",
    workouts: "אימונים",
    cantHaveMoreWorkouts: (number) =>
      `אי אפשר ליצור יותר מ-${number} אימונים בתוכנית אחת`,
    editExercise: "ערוך תרגיל",
    newExercise: "תרגיל חדש",
    name: "שם",
    exercise: "תרגיל",
    workoutPrograms: "תוכניות אימונים",
    add: "הוספה",
    remove: "הסרה",
    exercises: "תרגילים",
    restTimeBetweenSets: "זמן מנוחה בין תרגיל לתרגיל",
    sets: "סטים",
    confirm: "אישור",
    duration: "זמן",
    updateProgram: "עדכון תוכנית אימונים",
    suggestedFriends: "אנשים שאולי יתאימו לך",
    latestConfirmedWorkoutsWorldwide: "האימונים האחרונים שאושרו באפליקציה",
    mutualFriends: "חברים משותפים",
    added: "נוסף",
    changeFilters: "שינוי פילטרים",
    noWorkoutsMatchedYourSearch: "לא נמצאו אימונים שמתאימים לחיפוש שלך",
    haventConfirmedWorkoutsYet: "עדיין לא אושרו אימונים",
    publicProgram: "תוכנית ציבורית",
    youDontHaveScheduledWorkouts: "אין לך אימונים מתוכננים כרגע.",
    workoutNotFound: "האימון לא נמצא",
    itSeemsThatItGotCancledByTheCreator:
      "האימון כנראה בוטל על ידי מנהל האימון.",
    goToHomePage: "מעבר לדף הבית",
    howDoYouWantToCallYourWorkoutProgram: [
      "איך תרצי לקרוא לתוכנית האימונים?",
      "איך תרצה לקרוא לתוכנית האימונים?",
    ],
    rememberThisCantBeChangedLater: [
      "שימי לב, אי אפשר לשנות את השם אחר כך",
      "שים לב, אי אפשר לשנות את השם אחר כך",
    ],
    atLeast5Chars: "לפחות 5 תווים",
    whatIsIt: "מה זה אומר?",
    publicProgramInformationTitle:
      "תוכנית ציבורית יכולה להיראות ולהשמר על ידי משתמשים אחרים, אך אף אחד חוץ ממך לא יכול לערוך או למחוק אותה.",
    publicProgramInformationContent:
      "אי אפשר להפוך תוכנית ציבורית לפרטית לאחר שיצרת אותה.",
    newLeaderboardMessage: ({ lastPoints, lastLeague, lastPlace }) => {
      var leagueTitles = [
        "ליגת ברזל",
        "ליגת ארד",
        "ליגת כסף",
        "ליגת זהב",
        "ליגת פלטינום",
        "ליגת יהלום",
        "ליגת מאסטר",
        "ליגת גרנד-מאסטר",
        "ליגת הטוענים לכתר",
        "ליגת האלופים",
      ];
      if (lastPoints == 0) {
        return `לא קיבלת נקודות בשבוע האחרון ולכן ` + lastLeague == 0
          ? "נשארת בליגת ברזל"
          : "ירדת ל" + leagueTitles[lastLeague - 1];
      } else if (lastPlace <= 10) {
        return (
          `סיימת במקום ` +
          lastPlace +
          " בשבוע האחרון ולכן " +
          (lastLeague == leagueTitles.length - 1
            ? "נשארת ב" + leagueTitles[lastLeague]
            : "עלית ל" + leagueTitles[lastLeague + 1])
        );
      } else if (lastPlace >= 11 && lastPlace <= 40) {
        return (
          "סיימת במקום " +
          lastPlace +
          " בשבוע האחרון ולכן נשארת ב" +
          leagueTitles[lastLeague]
        );
      } else {
        return (
          "סיימת במקום " +
          lastPlace +
          " בשבוע האחרון ולכן " +
          (lastLeague == 0
            ? "נשארת בליגת ברזל"
            : "ירדת ל" + leagueTitles[lastLeague - 1])
        );
      }
    },
    pushNotificationModalTitle: [
      "קבלי התראות כשאחד החברים שלך קובע אימון!",
      "קבל התראות כשאחד החברים שלך קובע אימון!",
    ],
    pushNotificationModalMessage:
      "קבל התראות צ'אטים, אימונים של חברים, או סתם תזכורת להתאמן בשעה שקבעת לעצמך",
    noPublicProgramsFound: "לא נמצאו תוכניות אימונים ציבוריות.",
    createYourOwn: "צור אחת בעצמך",
    mostPopularPrograms: "תוכניות האימונים הכי פופולאריות",
    exploreNewPrograms: "מציאת תוכנית אימונים",
    followers: "עוקבים",
    saveProgram: "שמירת תוכנית",
    stopFollow: ["הפסיקי לעקוב", "הפסק לעקוב"],
    editProgram: "עריכת תוכנית",
    youHaventAddedFriendsYet: "לא הוספת חברים עדיין",
    areYouSureYouWantToDeleteMessages: (isMale) => {
      return isMale
        ? "אתה בטוח שאתה רוצה למחוק את ההודעות האלו?"
        : "את בטוחה שאת רוצה למחוק את ההודעות האלו?";
    },
    deleteForMe: "מחק בשבילי",
  },
};
export default languageService;
